import { Contacts } from '@components/Contacts';
import { Home } from '@components/Home';
import { MobileApp } from '@components/MobileApp';
import { MobileAppsList } from '@components/MobileAppsList';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

export const Main: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/contacts">
        <Contacts />
      </Route>
      <Route exact path="/apps">
        <MobileAppsList />
      </Route>
      <Route path={`/apps/:appId`}>
        <MobileApp />
      </Route>
    </Switch>
  );
};
