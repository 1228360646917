import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const Header: React.FC = () => {
  const location = useLocation();

  return (
    <header className="app-header">
      <div className="navigation">
        <Link to="/" className={`navigation__link ${location.pathname === '/' ? 'navigation__link_active' : ''}`}>
          Home
        </Link>
        <Link
          to="/apps"
          className={`navigation__link ${location.pathname.startsWith('/apps') ? 'navigation__link_active' : ''}`}
        >
          Apps
        </Link>
        <Link
          to="/contacts"
          className={`navigation__link ${location.pathname === '/contacts' ? 'navigation__link_active' : ''}`}
        >
          Contacts
        </Link>
      </div>
    </header>
  );
};
