import { Card } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { mobileApps } from '../data/mobileApps';

export const MobileAppsList: React.FC = () => {
  const history = useHistory();

  return (
    <div className="app-body">
      {mobileApps.map(app => (
        <Card
          key={app.id}
          hoverable
          style={{ width: 250 }}
          cover={<img src={app.img} alt={app.name} className={'mobile-app__image'} />}
          className={'mobile-app__image'}
          onClick={() => history.push(`apps/${app.id}`)}
        >
          <Card.Meta title={app.name} />
        </Card>
      ))}
    </div>
  );
};
