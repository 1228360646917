import React from 'react';

export const Contacts: React.FC = () => {
  return (
    <div className="app-body">
      <h2>Email</h2>
      <span className="contact">
        <a href="mailto:support@yooxen.com" className="contact__link">
          support@yooxen.com
        </a>
      </span>
    </div>
  );
};
