import React from 'react';

export const Home: React.FC = () => {
  return (
    <div className="app-body">
      <h1>
        <code>Yooxen</code>
      </h1>
      <span>
        <code>Technology, LLC</code>
      </span>
    </div>
  );
};
