import { MobileAppModel } from '../models/MobileAppModel';

export const mobileApps: MobileAppModel[] = [
  {
    id: 'yooxen-massword-manager',
    name: 'Yooxen Password Manager',
    img: 'img/yooxen-massword-manager_300x300bb.png',
    hrefIOS: 'https://apps.apple.com/bw/app/yooxen-password-manager/id1577352759',
    hrefAndroid: 'https://play.google.com/store/apps/details?id=com.yooxen.app.passwords',
  },
];
