import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { mobileApps } from '../data/mobileApps';

export const MobileApp: React.FC = () => {
  const { appId } = useParams();
  const app = useMemo(() => mobileApps.find(item => item.id === appId), [appId]);

  return app != null ? (
    <div className="app-body">
      <div key={app.id} className={'mobile-app'}>
        <div className={'mobile-app__title-block'}>
          <img width={100} height={100} src={`../${app.img}`} alt={app.name} className={'mobile-app__image'} />
          <p>{app.name}</p>
        </div>
        <div className={'mobile-app__store-buttons'}>
          <a href={app.hrefAndroid}>
            <img
              height={60}
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
          <a href={app.hrefIOS}>
            <img
              height={40}
              alt="Download on the App Store"
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
            />
          </a>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={'/apps'} />
  );
};
